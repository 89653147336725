// i18next-extract-mark-ns-start capabilities-friction-predictive

import { LINKS, FRICTION_NAV } from '@utils/constants';
import { graphql } from 'gatsby';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import React from 'react';

import Aside from '@components/Aside';
import AssetList from '@components/AssetList';
import Banner from '@components/Banner';
import Breadcrumbs, { Breadcrumb } from '@components/Breadcrumbs';
import Button from '@components/Button';
import PageSection from '@components/PageSection';
import Page from '@components/layout/Page';
import SEO from '@components/layout/SEO';
import ResourceListing from '@components/views/ResourceListing';
import { Link, Trans, useTranslation } from 'gatsby-plugin-react-i18next';

const CapabilityPredictiveFrictionPage = ({
	pageContext: {
		breadcrumb: { crumbs },
	},
	data
}: {
	pageContext: {
		breadcrumb: { crumbs: Breadcrumb[] };
	};
	data: any;
}) => {
	const { t } = useTranslation(); const wave = getImage(data.wave.childImageSharp.gatsbyImageData);

	return (
		<Page>
			<SEO
				title={t("seo_title")}
				description={t("seo_description")}
			/>

			<Banner
				title={t("title")}
				image={{
					src: data.banner.childImageSharp.gatsbyImageData,
					alt: '',
				}}
				size="standard"
			/>

			<Breadcrumbs crumbs={crumbs} />

			<PageSection
				className="section__content section__content--dual"
				layout="none">
				<div className="col xl7 m12 m-spacer page__content page__content--dual">
					<Trans i18nKey="content_1">
						<p>
							Predictive friction enables the rapid estimation of cycle averaged friction to assess the significant contributors
							to total engine Friction Mean Effective Pressure (FMEP), together with other types of losses.
							These include the energy required to drive oil, water and fuel pumps as well as losses such as windage,
							crankcase pumping and seal friction.
						</p>

						<p>
							The model data has been validated by comparison with measured data from many tear-down tests allowing for the following analysis:
						</p>

						<ul>
							<li>Rapid estimation of cycle-averaged friction to assess the significant contributors to total engine FMEP</li>
							<li>Covers the engine specification and enables design options to be assessed across operation parameters
								such as in-cylinder traces and engine design details including bearing diameters,
								shaft design and all sources of losses in an IC engine.</li>
							<li>Enabling predictive friction maps to be included in real-time engine plant models</li>
						</ul>
					</Trans>

					{wave && (
						<div className="product-lead-icon image-contain">
							<GatsbyImage
								image={wave}
								alt="WAVE"
								className="icon-image"
							/>
						</div>
					)}
					<Trans i18nKey="content_2">
						<p>
							WAVE is a 1-D fluid dynamics performance simulation used to help define requirements including engine architecture
							(configuration, sizing) to define targets (performance, fuel consumption, emissions).
							WAVE enables performance and acoustic simulations to be carried out based on virtually any intake, combustion
							and exhaust system configuration, and includes a drivetrain model to allow complete vehicle simulation.
							A predictive friction module called FAST is included, that analyses the effects of mechanical friction losses
							on engine performance.
						</p>
					</Trans>
				</div>
				<Aside>
					<Aside.Widget
						title={t("_MinimisingLosses")}
						className="widget__sibling__pages">
						<AssetList>
							{FRICTION_NAV.map((item, i) => (
								<Link
									key={i}
									to={item.value}
									className="assets__link"
									activeClassName="active">
									{t(item.labelkey) /* i18next-extract-disable-line */}
								</Link>
							))}
						</AssetList>
					</Aside.Widget>
					<Link
						to={LINKS.ABOUT_CONTACT}
						className="button--more">
						{t("_GetInTouch")}
					</Link>
				</Aside>
			</PageSection>
			<ResourceListing
				resources={data.resources}
				title={t("_OurInsights")}
				layout="fullwidth"
				actions={
					<div className="text--center">
						<Button to={LINKS.INSIGHTS} type="more">
							{t("_SeeAllInsights")}
						</Button>
					</div>
				}
			/>
		</Page>
	);
};

export default CapabilityPredictiveFrictionPage;

export const pageQuery = graphql`
query ($language: String!) {
	locales: allLocale(
		filter: { ns: { in: ["capabilities-friction-predictive", "_common"] }, language: { eq: $language } }
	) {
		edges {
			node {
				ns
				data
				language
			}
		}
	}
	banner: file(relativePath: { eq: "content/capabilities/friction/Realis-Simulation-ICE-Structural-Mechanics_1072.png" }) {
		...imageBreaker
	}
	wave: file(relativePath: { eq: "content/products/wave/rs_wave.png" }) {
		...imageBreaker
	}
	resources: allMarkdownRemark(
		filter: {
			fileAbsolutePath: { regex: "/resources/" }
			fields: { language: { eq: $language } }
			frontmatter: { capabilities: { in: "Friction" } }
		}
		sort: { fields: frontmatter___date, order: DESC }
		limit: 9
	) {
		nodes {
			fields {
				slug
			}
			frontmatter {
				title
				summary
				image {
					...imageStandard
				}
				type
			}
		}
	}
}
`;
